<template lang="pug">
.layout
  MainNavbar
  .content
    <slot />
</template>

<script setup>
import { useSettingsStore } from '~/stores/settings'

const settingsStore = useSettingsStore()
await settingsStore.getLangTree()
</script>


<style lang="scss" scoped>
.layout {
  --bcg-color: var(--light-color);
  --text-color: var(--dark-color);
  --bcg-menu-color: var(--light-color);

  width: 100%;
  min-height: 100vh;
  height: 100vh;
  margin: 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  background-color: #fff;
  position: relative;
}

.content {
  width: 100%;
  min-height: 100%;
  padding-left: 86px;

  @include mobile {
    padding-left: 0;
  }

  @media only screen and (width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
    padding-left: 0;
  }

  @media (min-width: 1024px) and (max-width: 1439px) and (min-height: 900px) {
    padding-left: 0;
  }

}
</style>
